// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import "prismjs/themes/prism.css"
///import "prismjs/themes/prism-tomorrow.css"
//import "prismjs/themes/prism-solarizedlight.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

export const onRouteUpdate = () => {
  const head = document.head
  const script = document.createElement("script")
  script.id = "cloudflare-web-analytics"
  script.defer = true
  script.src = "https://static.cloudflareinsights.com/beacon.min.js"
  const token = "f3b53c6d7e414f65ba4e0abfffb15df8"
  script.setAttribute("data-cf-beacon", `{"token": "${token}"}`)

  const existingScript = head.querySelector(`#${script.id}`)
  if (existingScript) {
    head.removeChild(existingScript)
  }

  head.appendChild(script)
}
